const COLORS = {
    PRIMARY:'#00695C',
    PRIMARY_LIGHT:'#00796B',
    PRIMARY_DARK:'#004D40',
    PRIMARY_EXTRA_LIGHT:'#E0F2F1',
    TEAL_50:'#E0F2F1',
    TEAL_100:'#B2DFDB',
    TEAL_200:'#80CBC4',
    TEAL_300:'#4DB6AC',
    TEAL_400:'#26A69A',
    TEAL_500:'#009688',
    TEAL_600:'#00897B',
    TEAL_700:'#00796B',
    TEAL_800:'#00695C',
    TEAL_900:'#004D40',
    TEAL_A100:'#A7FFEB',
    TEAL_A200:'#64FFDA',
    TEAL_A400:'#1DE9B6',
    TEAL_A700:'#00BFA5',
    DARK_BLUE:'#1A282B',
    RESUME_BACKGROUND:'#0A1930',
    RESUME_BACKGROUND_LIGHT:'#102544',
    RESUME_TEXT_GREEN:'#4ABDA9',
    RESUME_TEXT_LIGHT:'#D0DBFA',

}

export default COLORS;